.matching_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.matching_row {
  display: flex;
  height: 50px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.db_column {
  width: 50%;
  text-align: left;
}

.source_input {
  width: 50%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin: 0.6rem;
}

.source_input:focus {
  outline-color: #1976d2
}