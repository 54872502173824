.people_list_container {
  width: calc(100vw - 350px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  h2 {
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  .people_list_container {
    width: 100%;
  }
}