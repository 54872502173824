.bot_status {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.bot_list {
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.bot_card {
  width: 220px;

  border-radius: 0.5rem;
  overflow: hidden;
  background-color: rgb(234, 234, 237);
}

.bot_name {
  background-color: #1976d2;
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem;
  font-weight: 500;
}

.bot_update {
  padding: 0.5rem;
}