.validation_status {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
}

.status_container {
  display: flex;
  flex-direction: column;
}

.status_table {
  display: flex;
  flex-direction: column;

}

.table__row {
  display: flex;
  height: 50px;
}

.table__field {
  border: 1px solid black;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 150px;
}

.table__value {
  border: 1px solid black;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

}