.current_filers {
  border: 1px dashed gray;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  gap: 0.2rem
}