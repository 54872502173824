.table_container {
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}