.import-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0
  }
}

.import-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.dropzone_container {
  width: 100%;

  :hover {
    cursor: pointer;
  }
}

.dropzone {
  border: 2px dashed black;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  width: 100%;
  background-color: rgb(212, 212, 212);
  box-sizing: border-box;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    font-size: 1.5rem;
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dnc_checkbox {
  display: flex;
  font-size: 1.2rem;
  gap: 0.5rem;
  justify-content: center;

  input[type='checkbox'] {
    accent-color: red;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  label {
    color: red;
  }
}

@media screen and (max-width: 1200px) {
  .import-container {
    width: 80%;
  }
}